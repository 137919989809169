export default { "projects": [
	{title:"audi", youtube:"sI_Yj6LghVU", jpg:11},
	{title:"aviva-ironman", jpg:12},
	{title:"axe-numbers-game", jpg:9},	
	{title:"bates", jpg:4},
	{title:"bbc", jpg:2},
	{title:"bca", jpg:6},
	{title:"brands", youtube:"hvzPI_7Uyrs", jpg:5},
	{title:"brands-180years", youtube:"A1Std7EiHGo", jpg:11},
	{title:"bvi-bolt", jpg:4},
	{title:"bvi-hsm3", jpg:10},
	{title:"bvi-prince-caspian", jpg:10},
	{title:"bvi-scrooge", jpg:11},
	{title:"bvi-up", jpg:10},
	{title:"cadbury-5star-meme", jpg:4},	
	{title:"capitaland", jpg:4},
	{title:"carbid", youtube:"E11Dyy_IOUo", jpg:13},
	{title:"changi-angry-bird", jpg:9},	
	{title:"citibank-wake-up-fred", jpg:10},
	{title:"courts-crowds-for-codes", youtube:"FDz2VW58D90", jpg:5},
	{title:"courts-demolition", youtube:"7jQBNGBBf0w", jpg:3},
	{title:"dbs", jpg:8},
	{title:"domicil", jpg:5},
	{title:"edb", youtube:"_9bhF0vq6lk", jpg:14},
	{title:"exxonmobile-winner-circle", youtube:"370HBH8cAKI", jpg:13},
	{title:"first-himalayan", jpg:9},
	{title:"gta", jpg:12},
	{title:"johnsons-baby", jpg:5},	
	{title:"kose-wake-up-beautiful", jpg:5},
	{title:"lg", jpg:8},
	{title:"mastercard-cites", jpg:6},
	{title:"mcd-digital-signage", youtube:"_Pog5eDvhmE", jpg:9},
	{title:"mcd-hello-kitty", jpg:4},	
	{title:"mercedes-experience-the-star", jpg:9},
	{title:"moh-burst-the-silence", jpg:11},
	{title:"nhb-remember68", jpg:6},
	{title:"philips-plus-project", jpg:8},
	{title:"prudential-ecard", jpg:7, gif:6},
	{title:"redo", jpg:4},
	{title:"sentosa", jpg:8},
	{title:"starhub-safe-surf", youtube:"6tk0f7xydJY"},
	{title:"starwood-cdrom", jpg:3},
	{title:"starwood-china-destination", jpg:7},
	{title:"starwood-eat-drink-more", jpg:5},	
	{title:"stb", jpg:8},
	{title:"tiger", youtube:"vmmD-op_J0A"},
	{title:"uniqlo-airism", jpg:5},
	{title:"uniqlo-bugis-plus", jpg:7},
	{title:"uob-kiosk", jpg:8},
	{title:"ura", jpg:6},
	{title:"visa", jpg:5},
	{title:"vw-dsg-7speed", jpg:4},
	{title:"vw-social-beetle", youtube:"VLv2QN3ZMCs", jpg:4},
	{title:"wda", jpg:24},
	{title:"zouk-pee-analyser", youtube:"qFgH_4PkUF8", jpg:13}
]}