<template>
        <!-- {{project.title}} -->

    <h1 class="showcase-title">Project: {{project.title}}</h1>

    <div class="entry-holder">
      <div class="entry">
        <img  class='project-gif' :src="`./img/showcase/` + project.title + `.gif`" 
          @click.stop="onClickImage(project.title + `.gif`, -1)">
      </div>

      <div class="entry" v-for="i in project.jpg" :key="i">
          <img  class='project-gif' @click.stop="onClickImage(project.title, i)"
          :src="returnShowcaseImage(project.title, i)" >
      </div>

      <div class="entry" v-for="i in project.gif" :key="i">
          <img  class='project-gif' @click.stop="onClickImage(project.title, i, 'gif')"
          :src="returnShowcaseImage(project.title, i, 'gif')" >
      </div>


    </div>

    <div v-if="project.youtube" class="vid-holder">
      <iframe  :src="`https://www.youtube.com/embed/` + project.youtube" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class='pic-holder' v-if="selectedImage">
      <img :src="returnShowcaseImage(project.title, selectedImage, selectedImageType)" >
    </div>

</template>


<script>

import { onBeforeMount, onMounted, shallowRef, toRaw, markRaw, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import showcase from '@/helpers/Showcase';
import _ from "lodash";
import { useGtm } from '@gtm-support/vue-gtm';

export default {
  props: {
    project: { type: String, require: true, },
  },
  setup(props) {
    const selectedImage = ref(null);
    const selectedImageType = ref("jpg");
    const store = useStore()
    const gtm = useGtm();
    
    const project = _.find(showcase.projects, {title:props.project});
    console.log(project, "<<")

    const title = _.startCase(project.title);
    onBeforeMount( async() => {
      store.dispatch("site/setTitle", "LYHCO: " + title);
      const metadata = [
            { name:"description", content:"LYHCO Showase: " + title + " Project. Lyhco specialise in web technology, AWS cloud, interactive kiosk, social media and sensors." },
        ];
      store.dispatch("site/setMetadata", metadata);
    })
    
    // onMounted(()=>{

    // })
    const onClickImage = (title, i, type="jpg") => {
      selectedImage.value = i;
      selectedImageType.value = type;


      gtm.trackEvent({
        event: 'view-showcase-project',
        category: title,
        action: 'click',
        label: 'screenshot-'+(i+"").padStart(2, "0"),
        value: 5000,
        noninteraction: false
      });
    }    

    const returnShowcaseImage = (title, i, type="jpg") => {
      //console.log(i, type )
      if (i == -1) {
        return `./img/showcase/` + project.title + `.gif`;
      }
      //zerofill
      i = ('00'+i).slice(-2);
      if (type == "gif") {      
        return `./img/showcase-project/` + title + `/`+title+`-` + i +`.gif`;
      } else {
        return `./img/showcase-project/` + title + `/`+title+`-` + i +`.jpg`;
      }
    }


    return {
      project,
      selectedImage,
      selectedImageType,
      onClickImage,
      returnShowcaseImage,
    }
  }

};
</script>


<style lang="scss" scoped>

      h1.showcase-title { margin: 10px 5px 5px 5px; position: relative; font-size: 14px; text-transform:uppercase; }
      div.entry { display:inline-block; width:100px; height:57px; overflow:hidden; }
      div.entry img.project-gif { width:100px; padding:3px; cursor:pointer; }

      div.vid-holder { 
        width:100%;
        iframe {
          width:560px; height:315px;
          @media screen and (max-width: 580px) {
            width:100%;
          }
        }  
      }

      div.pic-holder {
        max-width:560px; height:315px;
        img {width:100%;}
      }

</style>

